<div class="header reveal">
  <div class="left">

    <a routerLink="/venues">
      <picture class="logo">
        <img src="https://static.fresto.io/fresto-corporate/images2/logo2/logo-light.svg" alt="home" height="16" width="auto">
      </picture>
    </a>
  </div>

  <div class="actions">
    <div class="menu-container">

      <div class="menu-button">
        <!-- <button mat-icon-button (click)="onOpenMenu.emit()" title="Menu" id="menuBtn" role="button">
          <mat-icon [class.primary]="visible || static">menu</mat-icon>
        </button> -->
      </div>
    </div>
  </div>
</div>
